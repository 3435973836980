<template>
  <m-context-menu
    ref="contextMenu"
    class="plannings-context-menu"
    @hide="reset"
  >
    <m-card
      v-if="showMenu"
      list
      no-padding
    >
      <m-card-item
        icon="delete"
        :tooltip="tooltip"
        :disabled="editableItems.length === 0"
        :loading="deleteLoading"
        @click="deleteItems"
      >
        {{ $t('general.delete') }}
        <template #right>
          <div
            v-if="cannotEditAllItems && editableItems.length > 0"

            class="_right"
          >
            <m-tooltip>
              <span>
                <m-icon
                  type="warning"
                  class="_icon"
                />
              </span>
              <template #title>
                {{ $t('planningsContextMenu.deleteHint') }}
              </template>
            </m-tooltip>
          </div>
        </template>
      </m-card-item>
    </m-card>
  </m-context-menu>
</template>

<script>
import usePlannings from '@/composables/planning/plannings';
import { accessPolicyType } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'PlanningsContextMenu',
  props: {
    ids: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showMenu: true,
      successMessage: '',
    };
  },
  setup() {
    const { plannings, deletePlannings, deletePlanningsLoading } = usePlannings();
    return { plannings, deletePlannings, deleteLoading: deletePlanningsLoading };
  },
  computed: {
    items() {
      return this.plannings.filter((m) => this.ids.includes(m.uid));
    },
    editableItems() {
      return this.items.filter((m) => [accessPolicyType.write, accessPolicyType.full].includes(m.accessRight));
    },
    cannotEditAllItems() {
      return this.editableItems.length !== this.items.length;
    },
    tooltip() {
      if (this.editableItems.length > 0) {
        return '';
      }

      return this.$t('planningsContextMenu.cannotDeleteMultiple');
    },
  },
  methods: {
    deleteItems() {
      this.hideMenu();
      const deleteMethod = () => this.deletePlannings(this.editableItems.map((e) => e.uid)).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));

      this.$confirm({
        title: this.$t('general.deletePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
    hideMenu() {
      this.$refs.contextMenu.hide();
    },
    show(event) {
      this.$refs.contextMenu.show(event);
    },
    onDelete() {
      this.$refs.contextMenu.hide();
    },
    reset() {
      this.showMenu = true;
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
